<mat-form-field class="w-full">
  <mat-select
    placeholder="Select a timezone"
    #singleSelect
    [(ngModel)]="selectedTimezone"
    [disabled]="disabled"
    (selectionChange)="onTimezoneChange()">
    <mat-option>
      <ngx-mat-select-search
        class="flex-auto"
        ngModel
        (ngModelChange)="filterTimezones($event)"
        placeholderLabel="Select timezone"
        noEntriesFoundLabel="No results"
        closeSvgIcon="close"></ngx-mat-select-search>
    </mat-option>

    <mat-option *ngFor="let opt of filteredTimezones" [value]="opt.id">
      {{ opt.displayName }}
    </mat-option>
  </mat-select>
</mat-form-field>
