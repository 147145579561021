import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InboxStateService } from '@app/inbox/inbox-state.service';
import { AppListType } from '@shared/AppEnums';
import { PluralOnNumber } from '@shared/AppFunctions';
import { InboxService } from '@shared/inbox-service/inbox.service';
import {
  AddLeadsFromConversationsToListDto,
  AddLeadsToListDto,
  CampaignSequenceStatesRequest,
  CampaignServiceProxy,
  InboxFiltersDto,
  LinkedInUserListDto,
  LinkedInUserListServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'add-leads-to-list',
  templateUrl: './add-leads-to-list-dialog.component.html',
})
export class AddLeadsToListComponent {
  isLoading: boolean = false;

  shouldAddLeadsToNewList: boolean = false;
  newListName: string = '';
  selectedList: LinkedInUserListDto = undefined;

  // From member ids
  memberIds: string[] = [];

  // From inbox
  excludeMemberIds: string[] = [];

  constructor(
    private _dialogRef: MatDialogRef<AddLeadsToListComponent>,
    private _linkedInUserListsService: LinkedInUserListServiceProxy,
    private campaignListService: CampaignServiceProxy,
    private _toaster: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      memberIds?: string[];
      excludeMemberIds?: string[];
      leadsCount?: number;
      selectedAll: boolean;
      inputDto: CampaignSequenceStatesRequest;
    },
  ) {}

  close(): void {
    this._dialogRef.close();
  }

  get isSubmitButtonDisabled(): boolean {
    return (
      this.isLoading ||
      (!this.selectedList && !this.shouldAddLeadsToNewList) ||
      (!this.newListName && this.shouldAddLeadsToNewList)
    );
  }

  async onSubmitClick(): Promise<void> {
    this.isLoading = true;
    let listId: number;
    if (this.shouldAddLeadsToNewList) {
      const newList = await this.createNewList();
      if (newList) {
        listId = newList.id;
      } else {
        this.isLoading = false;
        return;
      }
    } else {
      listId = this.selectedList.id;
    }

    this.campaignListService
      .addLeadsForCampaignToList({
        listId,
        filters: {
          selectAll: this.data.selectedAll,
          excludeIds: this.data.excludeMemberIds,
          includeIds: this.data.memberIds,
          filters: this.data.inputDto,
        },
      } as any)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        () => {
          this._toaster.open('Successfully added leads to list.', undefined, {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 4500,
          });
          this.close();
        },
        (error) => {
          console.error('Error adding leads to list:', error);
          this._toaster.open('Failed to add leads to list.', undefined, {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 4500,
          });
        },
      );
  }

  async createNewList(): Promise<LinkedInUserListDto> {
    try {
      const response = await this._linkedInUserListsService
        .createList(this.newListName, AppListType.USER_LIST)
        .toPromise();
      return response;
    } catch (error) {
      console.error('Error creating new list:', error);
      this._toaster.open('Failed to create new list.', undefined, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 4500,
      });
      return null;
    }
  }

  get getToggleButtonText(): string {
    return this.shouldAddLeadsToNewList
      ? 'add the leads to an existing list'
      : 'add the leads to a new list';
  }

  get getHint(): string {
    return `You are about to add ${this.data.leadsCount} lead${PluralOnNumber(this.data.leadsCount)} to a list`;
  }
}
