import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppConsts } from '@shared/AppConsts';
import { CampaignDto, CampaignServiceProxy } from '@shared/service-proxies/service-proxies';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { MAT_SELECT_CONFIG, MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'campaign-select',
  templateUrl: './campaign-select.component.html',
  styleUrls: ['campaign-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CampaignSelectComponent,
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        overlayPanelClass: 'w-auto',
      },
    },
  ],
})
export class CampaignSelectComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
  pageNumber: number = 0;
  loadedLastPage: boolean = false;
  searchTerm: string = '';
  campaigns: CampaignDto[] = [];
  @Input() selectedCampaigns: CampaignDto[] = [];
  @Input() labelText = 'Select the campaign you want to send notifications from';
  @Input() showLabel: boolean = false;
  @Input() showSecondLabel: boolean = false;
  @Input() filterOutDrafts: boolean = false;
  @Input() matToolTipForAllCampaigns?: string;
  @Output() selectedCampaignsChange: EventEmitter<CampaignDto[]> = new EventEmitter<
    CampaignDto[]
  >();
  @Output() clearFilters: EventEmitter<void> = new EventEmitter<void>();
  private value: any;
  searchTermUpdate = new Subject<string>();
  private destroy$: Subject<void> = new Subject<void>();
  @Input() placeholder: string = 'Select target campaign';
  scrollPositionUpdate = new Subject<void>();
  fetchingFromApi: boolean = false;
  // Manually debouncing scroll time prevents multiple consequitve API requests which otherwise happen due to the msInfiniteScrolLibrary
  lastScrollTime = 0;

  readonly allCampaignsSelector = {
    id: null,
    name: 'All Campaigns',
  } as CampaignDto;
  selectedCampaignsInternal: CampaignDto[] = [this.allCampaignsSelector];

  constructor(
    private _campaignService: CampaignServiceProxy,
    private cdRef: ChangeDetectorRef,
  ) {}
  writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onChange = (value: any): void => {
    // deliberately empty
  };

  public onTouched = (): void => {
    // deliberately empty
  };
  setDisabledState?(isDisabled: boolean): void {
    // deliberately empty
  }

  ngOnInit(): void {
    this.searchTermUpdate
      .pipe(
        debounceTime(AppConsts.searchInputDebounceTimeMillis),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((searchTerm) => {
        this.searchTerm = searchTerm;
        this.refresh();
      });

    this.scrollPositionUpdate
      .pipe(
        takeUntil(this.destroy$),
        filter(() => !this.fetchingFromApi),
      )
      .subscribe(() => {
        this.pageNumber++;
        this.getCampaigns();
      });

    this.refresh();
  }

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges?.selectedCampaigns?.currentValue) {
      if (this.selectedCampaigns.length === 0) {
        this.selectedCampaignsInternal = [this.allCampaignsSelector];
      } else {
        this.selectedCampaignsInternal = [...this.selectedCampaigns];
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCampaigns() {
    if (this.loadedLastPage) {
      return;
    }

    this.fetchingFromApi = true;
    let campaignStatuses = this.filterOutDrafts ? [1, 2, 3, 4, 5, 6, 7] : undefined;
    this._campaignService
      .getAll(this.pageNumber, this.searchTerm, campaignStatuses, undefined)
      .pipe(
        finalize(() => {
          this.fetchingFromApi = false;
          this.cdRef.detectChanges();
        }),
      )
      .subscribe((res) => {
        this.campaigns = this.campaigns.concat(res.items);
        this.loadedLastPage = res.items.length == 0;
      });
  }

  onScrolled() {
    if (this.lastScrollTime && this.lastScrollTime + 500 >= Date.now()) {
      return;
    }

    this.lastScrollTime = Date.now();

    this.scrollPositionUpdate.next();
  }
  refresh(): void {
    this.campaigns = [];
    this.pageNumber = 0;
    this.loadedLastPage = false;
    this.getCampaigns();
  }

  onSelectedCampaignsChange(change: MatSelectChange): void {
    const selectedCampaigns = change.value as CampaignDto[];
    const currentSelectionDoesntHaveAllCampaigns = !this.selectedCampaignsInternal.find(
      (c) => c.id === null,
    );
    const upcomingSelectionHasAllCampaigns = selectedCampaigns.some((c) => c.id === null);
    if (
      (currentSelectionDoesntHaveAllCampaigns && upcomingSelectionHasAllCampaigns) ||
      selectedCampaigns.length === 0
    ) {
      this.selectedCampaignsInternal = [this.allCampaignsSelector];
    } else {
      this.selectedCampaignsInternal = selectedCampaigns.filter((c) => c.id !== null);
    }
    this.selectedCampaignsChange.emit(
      this.selectedCampaignsInternal.filter((campaign) => campaign.id !== null),
    );
  }

  get filteredCampaigns(): CampaignDto[] {
    return this.campaigns.filter((x) => !this.selectedCampaignsInternal.find((z) => z.id === x.id));
  }

  get selectedCampaignsToShow(): CampaignDto[] {
    return this.selectedCampaignsInternal.filter((c) => c.id !== null);
  }

  onClearFilters() {
    this.searchTerm = '';
    this.selectedCampaignsChange.emit([]);
    this.selectedCampaignsInternal = [this.allCampaignsSelector];
    this.pageNumber = 0;
    this.loadedLastPage = false;
    this.fetchingFromApi = false;
    this.clearFilters.emit();
    this.refresh();
  }
}
