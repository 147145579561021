<div
  class="w-full flex flex-col text-center px-10 mx-auto"
  *ngIf="isSingleLoginInProgress$ | async; else notInProgress">
  <connect-progress></connect-progress>
</div>
<ng-template #notInProgress>
  <div class="w-full">
    <div class="flex">
      <!-- no account challenge -->
      <div
        class="w-full sm:px-0"
        *ngIf="
          (selectedAccountAuth$ | async)?.linkedInAccount?.accountState != LinkedInAccountState._3
        ">
        <div class="flex flex-col max-w-180 m-auto px-2 mt-0" *ngIf="!isUsingHeyReachExtension">
          <div class="flex flex-col" *ngIf="customProxyService.isUsingCustomProxy">
            <span class="text-dark text-3xl text-center font-extrabold mb-4">
              Connect with your own proxy
            </span>
            <div
              *ngIf="!isAgency"
              class="flex py-3 px-3 mb-2 text-md bg-sand-muted rounded-lg text-sand"
              role="alert">
              <mat-icon
                class="icon-size-5 relative top-1 text-sand"
                svgIcon="heroicons_solid:warning-triangle"></mat-icon>
              <span class="mt-1 ml-2">
                <p class="text-sm font-medium">
                  Adding your own proxy, can affect your overall {{ companyName }}
                  experience. Proceed only if you know what you are doing.
                </p>
              </span>
            </div>
            <span class="font-medium mt-3 mb-2 text-secondary text-md text-left">
              Verify your proxy
            </span>
            <proxy-select></proxy-select>
            <div class="w-full float-left mt-4">
              <span class="font-medium text-secondary">Your LinkedIn Email and Password</span>
              <div class="flex flex-row w-full justify-between">
                <label
                  class="flex-auto w-1/2 mt-2 mr-2 rounded-md border-2 border-secondary-muted p-2 px-3">
                  <input
                    class="w-11/12"
                    standardInput
                    autocomplete="new-password"
                    [ngModel]="(connectAccountInput$ | async)?.username"
                    (ngModelChange)="usernameInputChange($event)"
                    [disabled]="isSingleLoginInProgress$ | async" />
                </label>
                <password-visibility
                  [disableAutofill]="true"
                  class="w-1/2 mt-2 ml-2"
                  (passwordChange)="passwordInputChange($event)"></password-visibility>
              </div>
              <div class="flex flex-col mt-7">
                <linkedin-inbox-configuration
                  [label]="inboxScrapeConfigurationLabel"
                  [inboxConfigurations]="inboxConfigurations"
                  [inboxPrivacyConfiguration]="
                    (connectAccountInput$ | async).inboxScrapeConfiguration
                  "
                  (onSelectedInboxOptionChange)="
                    selectedInboxOptionChange($event)
                  "></linkedin-inbox-configuration>
              </div>
            </div>
          </div>
          <div
            *ngIf="!customProxyService.isUsingCustomProxy || isCustomProxyRequired"
            class="flex flex-col mt-0">
            <div *ngIf="!isAgency" class="mx-0">
              <div class="flex flex-col mb-6">
                <span class="font-medium text-secondary">Your LinkedIn Email address</span>
                <label
                  class="flex-auto w-full rounded-md border-2 border-secondary-muted p-2 px-3 mt-2">
                  <input
                    class="w-full text-md"
                    standardInput
                    autocomplete="new-password"
                    [ngModel]="(connectAccountInput$ | async)?.username"
                    (ngModelChange)="usernameInputChange($event)"
                    [disabled]="isSingleLoginInProgress$ | async" />
                </label>
              </div>
              <div class="flex flex-col">
                <span class="font-medium text-secondary mb-2">Your LinkedIn password</span>
                <password-visibility
                  [inputPasswordChange]="(connectAccountInput$ | async)?.password"
                  [disableAutofill]="true"
                  (passwordChange)="passwordInputChange($event)"></password-visibility>
              </div>
              <div class="flex flex-col gap-y-4 mt-7">
                <linkedin-inbox-configuration
                  [inboxConfigurations]="inboxConfigurations"
                  [label]="inboxScrapeConfigurationLabel"
                  [inboxPrivacyConfiguration]="
                    (connectAccountInput$ | async).inboxScrapeConfiguration
                  "
                  (onSelectedInboxOptionChange)="
                    selectedInboxOptionChange($event)
                  "></linkedin-inbox-configuration>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col mt-2"
            [ngClass]="{ 'mb-6': !customProxyService.isUsingCustomProxy }">
            <button
              buttonType="primary"
              heyreachButton
              class="w-full"
              [disabled]="
                connectAccountInput$
                  | async
                  | isConnectAccountFormDisabledPipe
                    : customProxyService.isUsingCustomProxy
                    : customProxyService.isVerificationStatus
              "
              (click)="connectAccount()">
              <div class="flex items-center">
                <mat-icon svgIcon="custom:linkedin" class="icon-size-5"></mat-icon>
                <span class="ml-2">Connect account</span>
              </div>
            </button>
          </div>
        </div>
        <div *ngIf="!isWhiteLabel" class="flex flex-col pt-0">
          <connect-account-extension
            (accountLinkedFromExtension)="onAccountLinked()"
            (onExtensionConnectClick)="extensionConnectClicked($event)"></connect-account-extension>
        </div>
        <div
          *ngIf="
            !customProxyService.isCustomProxyMandatory && customProxyService.isUsingCustomProxy
          ">
          <p class="text-center font-medium text-secondary mb-1 mt-3">
            switch back and use
            <span class="underline text-primary cursor-pointer" (click)="toggleAddProxy()">
              {{ companyName }} Proxies
            </span>
          </p>
        </div>
      </div>
      <!-- Single account pin verification -->
      <ng-container *ngIf="selectedAccountAuth$ | async as selectedAuth">
        <div
          *ngIf="selectedAuth.linkedInAccount?.accountState == LinkedInAccountState._3"
          class="w-full min-h-140 max-w-screen-md m-auto text-center px-0 flex flex-col mb-2 content-center items-center justify-items-center">
          <!-- PIN -->
          <ng-container
            *ngIf="
              selectedAuth.linkedInAccount?.accountStateReason == LinkedInAccountStateReason._6 ||
              selectedAuth.linkedInAccount?.accountStateReason == LinkedInAccountStateReason._8
            ">
            <div class="flex flex-col">
              <p class="text-dark text-3xl text-center font-extrabold mb-6">LinkedIn PIN Code</p>
            </div>
            <p class="w-full text-center text-secondary font-medium mb-8">
              As you have logged into LinkedIn from a new device, you need to verify this account.
              Check your email
              <span class="text-dark font-semibold">{{ connectedAccountEmail }}</span>
              for the PIN code that LinkedIn sent you, and enter it below.
            </p>
          </ng-container>
          <!-- Authenticator -->
          <ng-container
            *ngIf="
              selectedAuth.linkedInAccount?.accountStateReason == LinkedInAccountStateReason._9 ||
              selectedAuth.linkedInAccount?.accountStateReason == LinkedInAccountStateReason._11
            ">
            <div class="text-dark text-3xl text-center font-extrabold mb-6 mt-3 w-full">
              Authenticator app/SMS verification
            </div>
            <p class="text-secondary">
              Since you've logged into LinkedIn from a new location, we need to verify your account.
              Please use the authenticator app or check your SMS inbox for a 6-digit verification
              code. Enter the code below to validate your login access.
            </p>
          </ng-container>
          <div class="md:flex md:flex-col sm:block px-10">
            <div class="md:flex sm:block">
              <security-code
                #securityCode
                class="text-center"
                (pinCodeChange)="pinChange($event)"
                [pinCode]="(linkedInAccountAuthsPins$ | async)?.[selectedAuth.linkedInAccountId]"
                [disabled]="isProcessingPin$ | async"
                [applyRedBorder]="
                  (accountsWithIncorrectPin$ | async)?.[selectedAuth.linkedInAccountId] === false
                "></security-code>
              <button
                heyreachButton
                buttonType="primary"
                class="min-h-12 min-w-30 sm:mt-6 md:mt-0"
                [disabled]="
                  !(
                    (linkedInAccountAuthsPins$ | async)?.[selectedAuth.linkedInAccountId]
                    | isPinValidPipe
                  ) || (isProcessingPin$ | async)
                "
                (click)="verifyPin()">
                <div class="px-4" [loading]="isProcessingPin$ | async">Submit</div>
              </button>
            </div>
            <span
              class="text-warn font-medium flex justify-start text-base mt-2 ml-6"
              *ngIf="
                (accountsWithIncorrectPin$ | async)?.[
                  (selectedAccountAuth$ | async).linkedInAccountId
                ] === false
              ">
              Invalid PIN
            </span>
          </div>
          <div
            class="w-full relative text-center mt-8"
            *ngIf="
              selectedAuth.linkedInAccount?.challengeType == AppLinkedInChallengeType.PIN &&
              resendPinError
            ">
            <span class="text-secondary font-medium">
              Looks like you have trouble with the pin
              <span
                class="text-warn font-semibold underline cursor-pointer"
                (click)="onReConnectAccount()">
                Re-connect your account now
              </span>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
    <!-- Footer -->
    <div
      class="flex justify-center w-full text-secondary font-medium"
      *ngIf="
        !isAgency &&
        (selectedAccountAuth$ | async)?.linkedInAccount?.accountState != LinkedInAccountState._3 &&
        !customProxyService.isUsingCustomProxy &&
        !isUsingHeyReachExtension
      ">
      add your
      <span
        class="underline font-medium cursor-pointer text-primary mx-1"
        (click)="toggleAddProxy()">
        own proxy
      </span>
      here.
    </div>
  </div>
</ng-template>
