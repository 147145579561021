import { Injectable } from '@angular/core';
import {
  LinkedInAccountDto,
  LinkedInAccountServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LinkedInAccountSelectionService {
  private linkedInAccountsSubject$: BehaviorSubject<LinkedInAccountDto[]> = new BehaviorSubject<
    LinkedInAccountDto[]
  >([]);
  public linkedInAccounts$ = this.linkedInAccountsSubject$.asObservable();

  private selectedLinkedInAccountsSubject$: BehaviorSubject<LinkedInAccountDto[]> =
    new BehaviorSubject<LinkedInAccountDto[]>([]);
  public selectedLinkedInAccounts$ = this.selectedLinkedInAccountsSubject$.asObservable();

  constructor(private _linkedInAccountService: LinkedInAccountServiceProxy) {
    this.fetchLinkedInAccounts();
  }

  private fetchLinkedInAccounts() {
    this._linkedInAccountService.getLinkedInAccountsForTennant().subscribe((res) => {
      this.linkedInAccountsSubject$.next(res);
    });
  }

  public changeSelectedAccounts(selectedAccounts: LinkedInAccountDto[]) {
    this.selectedLinkedInAccountsSubject$.next(selectedAccounts);
  }
}
