import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InboxStateService } from '@app/inbox/inbox-state.service';
import { AppListType } from '@shared/AppEnums';
import { PluralOnNumber } from '@shared/AppFunctions';
import { InboxService } from '@shared/inbox-service/inbox.service';
import {
  AddLeadsFromConversationsToListDto,
  AddLeadsToListDto,
  InboxConversationDto,
  InboxFiltersDto,
  LinkedInUserListDto,
  LinkedInUserListServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'add-leads-from-member-ids-or-inbox',
  templateUrl: './add-leads-from-member-ids-or-inbox-dialog.component.html',
})
export class AddLeadsFromMemberIdsOrInboxComponent {
  isLoading: boolean = false;

  mode: AddLeadsFromMemberIdsOrInboxMode = 'INVALID_DATA';

  shouldAddLeadsToNewList: boolean = false;
  newListName: string = '';
  selectedList: LinkedInUserListDto = undefined;

  leadsCount: number = 0;

  // From member ids
  memberIds: string[] = [];

  // From inbox
  excludeMemberIds: string[] = [];
  inboxFilters: InboxFiltersDto;

  totalLeadsCount: number = 0;
  constructor(
    private _dialogRef: MatDialogRef<AddLeadsFromMemberIdsOrInboxComponent>,
    private _linkedInUserListsService: LinkedInUserListServiceProxy,
    private inboxService: InboxService,
    private inboxStateService: InboxStateService,
    private _toaster: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      memberIds?: string[];
      excludeMemberIds?: string[];
      inboxFilters?: InboxFiltersDto;
      leadsCount?: number;
      mode: AddLeadsFromMemberIdsOrInboxMode;
    },
  ) {
    this.inboxService.totalChatRooms$.subscribe((numberOfRooms) => {
      this.totalLeadsCount = numberOfRooms;
    });
    if (data.mode === 'FROM_MEMBER_IDS' && data.memberIds && data.memberIds.length > 0) {
      this.mode = 'FROM_MEMBER_IDS';
      this.memberIds = data.memberIds;
      this.leadsCount = this.memberIds.length;
    } else if (data.mode === 'FROM_INBOX' && data.inboxFilters && data.leadsCount) {
      this.mode = 'FROM_INBOX';
      this.leadsCount = data.leadsCount;
      this.inboxFilters = data.inboxFilters;
      this.excludeMemberIds = data.excludeMemberIds ? data.excludeMemberIds : [];
    } else {
      this.mode = 'INVALID_DATA';
      console.error('Invalid data passed to add leads from member ids dialog!');
    }
  }

  close(): void {
    this._dialogRef.close();
  }

  get isSubmitButtonDisabled(): boolean {
    return (
      this.isLoading ||
      (!this.selectedList && !this.shouldAddLeadsToNewList) ||
      (!this.newListName && this.shouldAddLeadsToNewList)
    );
  }

  async onSubmitClick(): Promise<void> {
    this.isLoading = true;
    let listId: number;
    const selectedChat = this.inboxService.selectedChatValue();

    if (this.shouldAddLeadsToNewList) {
      const newList = await this.createNewList();
      if (newList) {
        listId = newList.id;
      } else {
        this.isLoading = false;
        return;
      }
    } else {
      listId = this.selectedList.id;
    }

    let request;
    if (this.mode === 'FROM_MEMBER_IDS') {
      const dto = {
        linkedInProfileMemberIds: this.memberIds,
        listId,
      } as AddLeadsToListDto;

      request = this._linkedInUserListsService.addLeadsToListFromInbox(dto);
    }

    if (this.mode === 'FROM_INBOX') {
      const excludedConversations: InboxConversationDto[] =
        this.inboxStateService.getExcludedConversations();

      const dto = {
        filters: this.inboxFilters,
        excludeConversations: excludedConversations,
        listId: listId,
      } as AddLeadsFromConversationsToListDto;

      request = this._linkedInUserListsService.addLeadsToListFromInboxWithFilters(dto);
    }

    request
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        () => {
          this._toaster.open('Successfully added leads to list.', undefined, {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 4500,
          });
          this.close();
        },
        (error) => {
          console.error('Error adding leads to list:', error);
          this._toaster.open('Failed to add leads to list.', undefined, {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 4500,
          });
        },
      );
  }

  async createNewList(): Promise<LinkedInUserListDto> {
    try {
      const response = await this._linkedInUserListsService
        .createList(this.newListName, AppListType.USER_LIST)
        .toPromise();
      return response;
    } catch (error) {
      console.error('Error creating new list:', error);
      this._toaster.open('Failed to create new list.', undefined, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 4500,
      });
      return null;
    }
  }

  get getToggleButtonText(): string {
    return this.shouldAddLeadsToNewList
      ? 'add the leads to an existing list'
      : 'add the leads to a new list';
  }

  get getHint(): string {
    const result =
      this.leadsCount !== 1 && this.inboxStateService.selectedAll
        ? this.totalLeadsCount
        : this.leadsCount;
    return `You are about to add ${result} lead${PluralOnNumber(this.leadsCount)} to a list`;
  }
}

export type AddLeadsFromMemberIdsOrInboxMode = 'FROM_MEMBER_IDS' | 'FROM_INBOX' | 'INVALID_DATA';
